export default {
  path: "spl-company/:idElement",
  name: "MySplCompany",
  component: () => import(/* webpackChunkName: "my-spl-company-view" */ "@/views/private/MySplCompany.vue"),
  props: true,
  children: [
    {
      path: "anagrafica",
      name: "MySplCompanyAnagrafica",
      component: () => import(/* webpackChunkName: "my-spl-company-anagrafica" */ "@/components/my-spl-company/Anagrafica.vue"),
    },
    {
      path: "contatti",
      name: "MySplCompanyContatti",
      component: () => import(/* webpackChunkName: "my-spl-company-contatti" */ "@/components/my-spl-company/Contatti.vue"),
    },
    {
      path: "servizi",
      name: "MySplCompanyServizi",
      component: () => import(/* webpackChunkName: "my-spl-company-servizi" */ "@/components/my-spl-company/Servizi.vue"),
    },
    {
      path: "info",
      name: "MySplCompanyInfo",
      component: () => import(/* webpackChunkName: "my-spl-company-info" */ "@/components/my-spl-company/Info.vue"),
    },
    {
      path: "locations",
      name: "MySplCompanyLocations",
      component: () => import(/* webpackChunkName: "my-spl-company-locations" */ "@/components/my-spl-company/Locations.vue"),
      children: [
        {
          path: ":id?",
          name: "MySplCompanyLocationsEdit",
          component: () => import("@/components/my-spl-company/locations/EditForm.vue"),
        },
        {
          path: "new",
          name: "MySplCompanyLocationsNew",
          component: () => import("@/components/my-spl-company/locations/NewForm.vue"),
        },
      ],
    },
    {
      path: "funzionalita-aggiuntive",
      name: "MySplCompanyFunzionalitaAggiuntive",
      component: () => import(/* webpackChunkName: "my-spl-company-info" */ "@/components/my-spl-company/FunzionalitaAggiuntive.vue"),
    },
  ],
};
